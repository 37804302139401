import React from "react";
import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";

import clsx from "clsx";
import { useStaticQuery, graphql } from "gatsby";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { Button } from "~components/utils/button";

const Component = () => {
  const data = useStaticQuery(graphql`
    query Reports210401Pwn2OwnPage {
      shisho: file(
        relativePath: { eq: "reports/shisho.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pwn2own: file(
        relativePath: { eq: "reports/pwn2own.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <PageLayout
      paddingPattern="fit-header"
      meta={{
        title:
          "Ubuntu Desktop Exploit | Pwn2Own Local Escalation of Privilege Category",
        description:
          "This whitepaper describes the vulnerability used for PWN2OWN 2021 of Local Escalation of Privilege Category. This exploit and vulnerability were tested against the latest release of Ubuntu Desktop 20.10 at the time of writing.",
      }}
    >
      <div
        className={clsx("bg-medium-gray text-black pt-8 pb-2 px-16 text-left")}
      >
        <div className={clsx("my-0 mx-auto mb-10")}>
          <time
            dateTime="2021.04.01"
            className={clsx("font-bold text-m text-gray")}
          >
            2021.04.01
          </time>
          <div className={clsx("text-m font-bold text-gray")}>
            Pwn2Own Local Escalation of Privilege Category
          </div>
          <h1
            className={clsx("pt-6 font-bold text-xl text-flatt-red text-left")}
          >
            Ubuntu Desktop Exploit
          </h1>
        </div>
      </div>

      <div className="px-16 py-24">
        <div className="max-w-4xl  m-auto smallpc-mediumpc:max-w-5xl">
          <OptionalGatsbyImage
            data={data.pwn2own}
            alt="Reports"
            className="mb-10"
          />
          <a href="/assets/reports/210401_pwn2own/whitepaper.pdf">
            <Button buttonSize="Xlarge" className="w-full">
              See Reports PDF
            </Button>
          </a>
          <h2 className={clsx("mt-12 text-s text-center text-flatt-red")}>
            Disclaimer
          </h2>
          <p className={clsx("mt-4 text-xs text-flatt-red")}>
            <i>
              Flatt Security Inc. has disclosed this document for educational
              purposes. This document includes snippets of codes used for the
              vulnerability’s actual PoC(Proof of Concept). However, any
              explanations or snippets provided within this document are without
              warranty of any kind, and its use is at the user’s sole risk.
              Flatt Security Inc. does not accept any responsibility or
              liability for the content, completeness, legality, or reliability
              of the content provided in the document. Flatt Security Inc. shall
              not be liable for any loss or damage of whatever nature (direct,
              indirect, consequential, or other), whether arising in contract,
              tort, or otherwise, which may occur as a result of your use of, or
              inability to use, any information or additional information
              provided by us in direct or indirect relation to the document
              provided in this page.
            </i>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default withContext(Component);
